import React, {useState, useEffect} from 'react';
import { log } from 'three/examples/jsm/nodes/Nodes.js';

export default function Ticket() {
    const [auth] = useState(JSON.parse(window.localStorage.getItem('auth')));
    const [data, setData] = useState([])

    useEffect(() => {
        if(auth === null){
            window.history.pushState({}, undefined, "/login")
            window.location.reload()
        }else{
            getTickets()
        }
    }, [])
    const getTickets = () => {
        fetch(process.env.REACT_APP_APIURL + '/access/getAllReport', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.token}` },
        })
        .then(res => res.json())
        .then(data => {
            if(data.error){
                window.history.pushState({}, undefined, "/login")
                window.location.reload()
            }
            else{
                setData(data)
            }
        })
    }
    const deleteTicket = (e, el) => {
        e.preventDefault()
        fetch(process.env.REACT_APP_APIURL + '/access/deleteReport/' + el._id, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth.token}` },
        })
        .then(res => res.json())
        .then(data => {
            if(data.error){
                window.history.pushState({}, undefined, "/login")
                window.location.reload()
            }
            else{
                setData([])
                getTickets()
            }
        })
    }

    return (
        <div className="Ticket" style={{'width': '-webkit-fill-available'}}>
            <div className='table'>
                <table>
                    <thead>
                        <tr>
                            <th colSpan="5">Tickets</th>
                        </tr>
                        <tr>
                            <th>Date</th>
                            <th>SteamId</th>
                            <th>Description</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((el, index) => {
                        return (
                        <tr key={index}>
                            <td>{
                                new Date(el.date).getDate() + "/" + new Date(el.date).getMonth() + " " +
                                new Date(el.date).getHours() + ":" + (new Date(el.date).getMinutes() < 10 ? "0" + new Date(el.date).getMinutes() : new Date(el.date).getMinutes())
                            }</td>
                            <td>{el.steamid}</td>
                            <td style={{'width': '99%'}}>{el.description}</td>
                            <td>
                                <button onClick={(e) => deleteTicket(e, el)} style={{'padding': '0px', 'width': '35px', 'height': '35px' }} className='button-c'>
                                    <img style={{'verticalAlign': 'middle'}} src="https://img.icons8.com/dotty/20/9BA9B4/delete-sign.png"/>
                                </button>
                            </td>
                        </tr>
                        )}
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}